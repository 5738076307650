



































































import Vue from "vue";
import * as _ from "lodash";
import db from "@/firebase/db";
import DBHelper from "@/tscript/dbHelper";
import Header from "@/components/global/Header.vue";
import { mapState, mapActions } from "vuex";
import MycCard from "../global/cards/MycCard.vue";
import MycDialog from "@/components/global/dialog/MycDialog.vue";

export default Vue.extend({
  name: "HomeGroup",
  components: {
    Header,
    MycCard,
    MycDialog,
  },
  data() {
    return {
      dbHelper: new DBHelper(db),
      openNewGroupDialog: false,
      discussionsList: new Array(),
      loading: false,
      group: {} as any,
      valid: true,
      dialogDiscussion: false,
      userIdToSend: "",
      usersListNetwork: new Array(),
    };
  },
  methods: {
    ...mapActions("analytics", ["logAction"]),
    async loadData() {
      this.loading = true;
      const constraints: any = {
        where: [
          {
            field: "participants",
            compare: "array-contains",
            value: this.userData.id,
          },
        ],
      };
      this.discussionsList =
        await this.dbHelper.getAllDataFromCollectionWithAll(
          "discussions",
          constraints
        );
      this.loading = false;
    },
    goDiscussion(discussionId: any) {
      this.$router
        .push({ name: "discussion", params: { id: discussionId } })
        .catch(() => {});
    },
    findName(participants: any) {
      const destId =
        participants[0] == this.userData.id ? participants[1] : participants[0];
      const user = _.find(this.$store.state.usersList, { id: destId });

      return user.last_name + " " + user.first_name;
    },
    openDiscussion() {
      this.goDiscussion(this.userIdToSend);
      this.closeShareDialog();
    },
    closeShareDialog() {
      this.dialogDiscussion = false;
      this.userIdToSend = "";
    },
  },
  created() {
    this.logAction({ event_name: "home_discussions" });
    this.usersListNetwork = this.$store.state.usersList.filter((user: any) =>
      this.userData.network.current.includes(user.id)
    );
    this.usersListNetwork.map((item) => {
      item.name = item.last_name + " " + item.first_name;
      return item;
    });
    this.loadData();
  },
  computed: {
    ...mapState("user", ["userData"]),
  },
});
